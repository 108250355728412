import './styles/App.css';
import { Route, Routes } from 'react-router-dom';
import { Home, Product, LandingProduct, Order, Products } from './pages';
import ReactPixel from 'react-facebook-pixel';
import { useEffect, useState } from 'react';
import ky from 'ky';
import useStore from './store/useStore';
import { HelmetProvider, Helmet } from 'react-helmet-async';


function App() {
    const dataStore = useStore((state: any) => state.store);

    useEffect(() => {
        ky.get(`${process.env.REACT_APP_API_URL}/store`).json()
        .then((data: any) => {
            // cookies.set('store', JSON.stringify(dataStore));
            useStore.setState({ store: data });
        })
        .catch((error) => {
            console.log(error);
        })
    }, [])
    
    useEffect(() => {
        ky.get(`${process.env.REACT_APP_API_URL}/pixels`).json()
        .then((data: any) => {
            const advancedMatching = undefined;
            data?.map((item: any, index: number) => {
                if (item?.apiKey !== "" && item?.apiKey.length > 10 && !item?.idMarketer) {
                    ReactPixel.init(item?.apiKey, advancedMatching, {
                        autoConfig: true,
                        debug: true
                    });
                }
            })
            ReactPixel.pageView();
        })
        .catch((error) => console.log(error));
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <link rel="icon" href={dataStore?.icon && dataStore?.icon !== "" ? `${process.env.REACT_APP_API_URL_IMAGES}/${dataStore?.icon}.webp` : "/favicon.ico"} />
                <title>{dataStore?.information?.title || ""}</title>
                <meta name="description" content={dataStore?.information?.shortDescription || ""} />
            </Helmet>
            <Routes>
                <Route index path="/" element={<Home />} />
                <Route index path="/product/:id" element={<Product />} />
                <Route index path="/products/:category" element={<Products />} />
                <Route index path="/:id" element={<LandingProduct />} />
                <Route index path="/order/:status/:type?/:id?/:isMany?/:ref?" element={<Order />} />
            </Routes>
        </HelmetProvider>
    );
}

export default App;
